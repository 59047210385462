<template>
  <div class="cooperation-home">
    <img class="bg-img" src="../../assets/images/enterprise/cooperation/bg.png" alt="" />
    <div class="handshake">
      <img src="../../assets/images/enterprise/cooperation/handshake.png" alt="" />
    </div>
    <div class="center-items">
      <div class="contract-template">
        <a href="/static/contract_template.docx" download>
          <img
            src="../../assets/images/enterprise/cooperation/contract-template.png"
            alt=""
          />
        </a>
      </div>
      <CustomerService class="CustomerService"></CustomerService>
    </div>
    <div class="upload-contract">
      <!-- <img src="../../assets/images/enterprise/cooperation/upload-contract.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
import CustomerService from "../../components/CustomerService.vue";
export default {
  components: { CustomerService },
  data() {
    return {};
  },
  mounted() {
    document.getElementsByClassName("content")[0].style.paddingRight = 0;
  },
  methods: {},
  beforeDestroy() {
    document.getElementsByClassName("content")[0].style.paddingRight = "5vw";
  },
};
</script>

<style lang="less" scoped>
.cooperation-home {
  position: relative;
  height: 38vw;
  .bg-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 91%;
  }
  .handshake {
    width: 17vw;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .center-items {
    display: flex;
    justify-content: space-between;
    .contract-template {
      width: 10vw;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
    .CustomerService {
      position: relative;
      top: -6vw;
    }
  }
  .upload-contract {
    width: 12vw;
    margin: 0 auto;
    position: relative;
    top: -13vw;
    left: 1vw;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
</style>
