<template>
  <div class="kefu">
    <img
      class="kefu-top"
      src="../assets/images/communication/kefu.png"
      alt=""
      @click="init"
    />
    <img class="kefu-ren" src="../assets/images/communication/kefu-ren.png" alt="" />
    <el-dialog class="dialog" :visible.sync="dialogVisible">
      <div slot="title">
        <div class="header">
          <div class="title">艺大佬客服</div>
          <div class="text">在线人工服务时间：9：00-20：00</div>
          <!-- <div class="text zhuan">转人工</div> -->
        </div>
      </div>
      <div class="content">
        <div class="left">
          <div class="message-info" ref="messageList">
            <div v-for="(chat_record, index) in messageList" :key="index">
              <div
                class="message"
                :class="item.sender == 'me' ? 'reverse' : ''"
                v-for="item in chat_record.data"
                :key="item.id"
              >
                <img
                  class="message-img"
                  :src="require('../assets/images/kefu.png')"
                  alt=""
                />
                <div class="info">
                  <div class="name">{{ item.nickname }}</div>
                  <div v-html="item.message" class="info-content"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="dialog-item">
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 }"
              placeholder="请输入您想咨询的问题"
              v-model="message"
              @keyup.enter.native="send_message"
            ></el-input>
            <div class="send-img">
              <img
                src="../assets/images/send-message.png"
                alt=""
                @click="send_message"
              />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">快捷提问：</div>
          <div
            class="text"
            @click="
              message = item.questions;
              send_message();
            "
            v-for="item in kbsList"
            :key="item.id"
            v-show="item.status == 1"
          >
            {{ item.questions }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      kbsList: [],
      dialogVisible: false,
      message: "",
      socket: "",
      messageList: [],
      chatRecordPage: 1,
      ws: {
        SocketTask: null,
        Timer: null, // 计时器
        ErrorMsg: [], // 发送失败的消息
        MaxRetryCount: 3, // 最大重连次数
        socketOpen: false,
      },
      csr: "", // 当前用户的客服代表ID
      sessionId: 0, // 会话ID
      errorTips: "连接中...",
    };
  },
  watch: {
    messageList: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          var messageList = this.$refs.messageList;
          messageList.scrollTop = messageList.scrollHeight;
        });
      },
    },
  },
  mounted() {},
  methods: {
    init: function () {
      this.dialogVisible = true;
      let data = {
        token: this.vuex_userinfo.token,
        kefu_tourists_token: this.kefu_tourists_token,
        fixed_csr: "",
      };
      this.$api.kefu.initialize(data).then((res) => {
        let { kefu_tourists_token } = res.data.token_list;
        let { new_msg, config } = res.data;
        // 配置
        this.config = config;
        // 保存游客token
        if (kefu_tourists_token) {
          this.$ku.vuex("kefu_tourists_token", kefu_tourists_token);
        }

        // 新消息提示
        if (new_msg) {
          this.new_message_prompt();
        }

        // 连接ws
        this.connect_socket();
      });
      // 获取知识库列表
      this.$api.kefu.getkbs().then((res) => {
        this.kbsList = res.data;
      });
    },
    // 新消息提示
    new_message_prompt() {
      let audio = new Audio();
      audio.src = this.config.upload.cdnurl + this.config.ringing;
      audio.play();
    },
    // 连接ws
    connect_socket: function () {
      if (this.ws.SocketTask && this.ws.socketOpen) {
        return;
      }
      let token = this.vuex_userinfo.token
        ? "&token=" + this.vuex_userinfo.token
        : "";
      let kefu_tourists_token = this.kefu_tourists_token
        ? "&kefu_tourists_token=" + this.kefu_tourists_token
        : "";
      let path = (
        axios.defaults.wsURL +
        ":" +
        this.config.websocket_port +
        "/?modulename=index" +
        token +
        kefu_tourists_token
      ).replace(/\|/g, "~");
      // 实例化socket
      this.socket = new WebSocket(path);
      // 监听socket连接
      this.socket.onopen = this.open;
      // 监听socket错误信息
      this.socket.onerror = this.error;
      // 监听socket消息
      this.socket.onmessage = this.getMessage;
    },
    open: function () {
      this.ws.SocketTask = true;
      this.ws.socketOpen = true;
      // 重新发送所有出错的消息
      for (let i in this.ws.ErrorMsg) {
        this.ws_send(this.ws.ErrorMsg[i]);
      }
      this.ws.ErrorMsg = [];
      this.errorTips = "";

      if (this.ws.Timer != null) {
        clearInterval(this.ws.Timer);
      }

      this.ws.Timer = setInterval(this.ws_send, 28000); //定时发送心跳
    },
    error: function () {
      this.ws.socketOpen = false;
      this.errorTips = "WebSocket 发生错误!";
    },
    getMessage: function (res) {
      let msg = JSON.parse(res.data);
      let actions = this.domsg();
      let action = actions[msg.msgtype] || actions["default"];
      action.call(this, msg);
    },
    // 发送ws消息
    ws_send: function (message) {
      if (!message) {
        message = {
          c: "Message",
          a: "ping",
        };
      }

      if (this.ws.SocketTask && this.ws.socketOpen) {
        this.socket.send(JSON.stringify(message));
      } else {
        this.ws.ErrorMsg.push(message);
      }
    },

    send_message: function (e) {
      if (e?.ctrlKey && e.keyCode == 13) {
        this.message += "\n";
        return;
      }
      if (!this.message) {
        return;
      }
      // 检查 websocket 是否连接
      if (!this.ws.SocketTask || !this.ws.socketOpen) {
        this.$message.error("网络链接异常，请稍后重试~");
        return;
      }

      if (!this.sessionId) {
        this.$message.error("初始化未完成(会话获取失败)~");
        return;
      }

      let message_data = this.messageList[this.messageList.length - 1].data;
      var message_id = parseInt(message_data[message_data.length - 1].id) + 1;
      // var message_id = new Date().getTime() + this.sessionId + Math.floor(Math.random() * 10000);
      var load_message = {
        c: "Message",
        a: "sendMessage",
        data: {
          message: this.message,
          message_type: 0,
          session_id: this.sessionId,
          modulename: "index",
          message_id: message_id,
        },
      };

      this.ws_send(load_message);

      var data = {
        id: message_id,
        status: 2, // 标记待发送状态
        sender: "me",
        message: this.message,
        message_type: 0,
      };

      var messageListIndex = this.messageList.length - 1;
      if (
        this.messageList[messageListIndex] &&
        this.messageList[messageListIndex].datetime == "刚刚"
      ) {
        this.messageList[messageListIndex].data = this.messageList[
          messageListIndex
        ].data.concat(this.format_message([data]));
      } else {
        this.messageList = this.messageList.concat({
          datetime: "刚刚",
          data: this.format_message([data]),
        });
      }

      this.message = "";
    },
    // eslint-disable-next-line no-unused-vars
    domsg: function (msg) {
      return {
        // eslint-disable-next-line no-unused-vars
        default: (msg) => {},
        initialize: (msg) => {
          if (msg.data.new_msg) {
            this.new_message_prompt();
          }

          // 分配/获取客服->获取聊天记录
          var user_initialize = {
            c: "Message",
            a: "userInitialize",
            data: {
              fixed_csr: this.fixedCsr,
            },
          };
          this.ws_send(user_initialize);
        },
        user_initialize: (msg) => {
          // 用户客服分配结束
          if (msg.code == 1) {
            if (msg.data.session.user_tourists) {
              this.send_message = function () {
                this.$message({
                  message: "为保护您的隐私请,请登录后发送消息~",
                  type: "warning",
                });
              };
            }

            this.csr = msg.data.session.csr;
            this.sessionId = msg.data.session.id;
          } else if (msg.code == 302) {
            if (!this.csr) {
              // 打开留言板
              this.csr = "none";
            }
          }
        },
        clear: (msg) => {
          if (msg.msg) {
            // uni.showToast({
            //   title: msg.msg,
            //   icon: "none",
            // });
          }

          var clear = {
            c: "Message",
            a: "clear",
          };
          this.ws_send(clear);
        },
        new_message: (msg) => {
          this.new_message_prompt();

          if (msg.data.session_id == this.sessionId) {
            var messageListIndex = this.messageList.length - 1;
            msg.data.id = msg.data.record_id;
            if (this.messageList[messageListIndex].datetime == "刚刚") {
              this.messageList[messageListIndex].data = this.messageList[
                messageListIndex
              ].data.concat(this.format_message([msg.data]));
            } else {
              this.messageList = this.messageList.concat({
                datetime: "刚刚",
                data: this.format_message([msg.data]),
              });
            }

            var load_message = {
              c: "Message",
              a: "readMessage",
              data: {
                record_id: msg.data.record_id,
                session_id: this.sessionId,
              },
            };

            this.ws_send(load_message);
          }
        },
        chat_record: (msg) => {
          let { chat_record, next_page, page } = msg.data;
          this.chatRecordPage = next_page;
          for (let i in chat_record) {
            chat_record[i].data = this.format_message(chat_record[i].data);
          }
          if (page == 1) {
            this.messageList = chat_record;
          } else {
            for (let i = chat_record.length - 1; i >= 0; i--) {
              chat_record[i].data.reverse();
              this.messageList.unshift(chat_record[i]);
            }
          }
        },
        send_message: (msg) => {
          if (!msg.data.message_id) {
            return;
          }

          var messageListIndex = this.messageList.length - 1;
          for (let i in this.messageList[messageListIndex].data) {
            if (
              this.messageList[messageListIndex].data[i].id ==
              msg.data.message_id
            ) {
              this.messageList[messageListIndex].data[i].status =
                msg.code == 1 ? 0 : 3;
              this.messageList[messageListIndex].data[i].id = msg.data.id;
            }
          }
        },
      };
    },
    close: function () {
    },
    format_message: function (message) {
      for (let i in message) {
        if (message[i].message_type == 4 || message[i].message_type == 5) {
          var message_arr = message[i].message.split("#");
          var message_obj = {};

          for (let y in message_arr) {
            let message_temp = message_arr[y].split("=");
            if (typeof message_temp[1] != "undefined") {
              message_obj[message_temp[0]] = message_temp[1];
            }
          }
          message[i].message = message_obj;
        }
      }
      return message;
    },
  },
  destroyed() {
    // 销毁监听
    if (this.ws.SocketTask && this.ws.socketOpen && this.ws.pageHideCloseWs) {
      this.socket.onclose = this.close();
    }
  },
};
</script>
<style lang="less" scoped>
.kefu {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .kefu-top {
    width: 7vw;
    position: relative;
    top: 5%;
    cursor: pointer;
  }
  .kefu-ren {
    width: 20vw;
  }

  .dialog {
    .header {
      display: flex;
      align-items: center;
      .title {
        font-size: 1vw;
        color: #383838;
        margin-right: 1vw;
        font-weight: 800;
      }
      .text {
        color: #a3a3a3;
        font-size: 0.5vw;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }
      .zhuan {
        margin-left: 1vw;
        cursor: pointer;
      }
    }

    .content {
      display: flex;
      .left {
        flex: 1;
        min-width: 70%;
        .message-info {
          background: #f8f8f8;
          padding: 8px;
          height: 25vw;
          overflow: auto;
          .reverse {
            flex-flow: row-reverse;
            padding-right: 0 !important;
            padding-left: 20%;
            .info {
              .name {
                text-align: right;
              }
              .info-content {
                border-radius: 10px 0px 10px 10px !important;
              }
            }
          }
          .message {
            display: flex;
            padding-right: 20%;
            &-img {
              width: 35px;
              height: 35px;
              border-radius: 35px;
            }
            .info {
              margin-left: 6px;
              text-align: left;
              .name {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #1bad72;
                line-height: 35px;
              }
              .info-content {
                background: #ffffff;
                border-radius: 0px 10px 10px 10px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #383838;
                padding: 13px 8px;
              }
            }
          }
        }
        .dialog-item {
          padding: 9px 12px;
          .send-img {
            text-align: right;
            margin-top: 6px;
            img {
              cursor: pointer;
            }
          }
        }
      }
      .right {
        padding: 13px 11px;
        text-align: left;
        .title {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 800;
          color: #383838;
        }
        .text {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #7b7b7b;
          margin-top: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
